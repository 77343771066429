import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

if (
	import.meta.env.VITE_SENTRY_DNS &&
	import.meta.env.VITE_SENTRY_RELEASE_NAME
) {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DNS,
		environment: import.meta.env.VITE_ENV_NAME,
		release: import.meta.env.VITE_SENTRY_RELEASE_NAME,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
		ignoreErrors: [/Failed to fetch dynamically imported module/],
	})
}
